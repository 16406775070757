// Layout
const dashboardLayout = () => import('@/layouts/DoctorLayout/DashboardLayout/dashboardLayout.vue')

const financialOperations = () => import('./views/index')

// start Routes
export default [
  {
    path: '/doctor/financialOperations',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'doctorFinancialOperations',
      meta: {
        name: 'doctorFinancialOperations',
        parentName: 'marketplace',
        userType: '',
        permission: '',
        role: 'veterinary.doctor'
      },
      component: financialOperations
    }]
  }
]
