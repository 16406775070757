// Layout
const dashboardLayout = () => import('@/layouts/DoctorLayout/DashboardLayout/dashboardLayout.vue')

const superAdminDashboard = () => import('./views/index')

// start Routes
export default [
  {
    path: '/doctor',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'doctorDashboard',
      meta: {
        name: 'doctorDashboard',
        userType: '',
        permission: '',
        role: 'veterinary.doctor'
      },
      component: superAdminDashboard
    }
    ]
  }
]
