// Layout
const dashboardLayout = () => import('@/layouts/HospitalLayout/DashboardLayout/dashboardLayout.vue')

const superAdminDashboard = () => import('./views/index')

// start Routes
export default [
  {
    path: '/hospital',
    component: dashboardLayout,
    meta: {
      role: 'veterinary.clinic_owner'
    },
    children: [{
      path: '',
      name: 'hospitalDashboard',
      meta: {
        name: 'hospitalDashboard',
        userType: '',
        permission: '',
        role: 'veterinary.clinic_owner'
      },
      component: superAdminDashboard
    }
    ]
  }
]
