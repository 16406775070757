// Layout
const dashboardLayout = () => import('@/layouts/DoctorLayout/DashboardLayout/dashboardLayout.vue')

const books = () => import('./views/index')
/* const addProduct = () => import('./views/addProduct') */

// start Routes
export default [
  {
    path: '/doctor/books',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'doctorBooks',
      meta: {
        name: 'doctorBooks',
        parentName: 'marketplace',
        userType: '',
        permission: '',
        role: 'veterinary.doctor'
      },
      component: books
    }]
  }
]
