/*eslint-disable */
const dashboardLayout = () =>
  import("@/layouts/HospitalLayout/DashboardLayout/dashboardLayout.vue");

const finances = () => import("./views/index.vue");

// start Routes
export default [
  {
    path: "/hospital/finances",
    component: dashboardLayout,
    children: [
      {
        path: "",
        name: "hospitalFinances",
        meta: {
          name: "hospitalFinances",
          parentName: "marketplace",
          userType: "",
          permission: "",
          role: "veterinary.clinic_owner",
        },
        component: finances,
      },
    ],
  },
];
