// Layout
/*eslint-disable*/
const dashboardLayout = () =>
  import("@/layouts/HospitalLayout/DashboardLayout/dashboardLayout.vue");
const doctorSetting = () => import("./views/");
const setting = () => import("./views/index");

// start Routes
export default [
  {
    path: "/hospital/setting",
    component: dashboardLayout,
    children: [
      {
        path: "",
        name: "hospitalSetting",
        meta: {
          name: "hospitalSetting",
          parentName: "marketplace",
          userType: "",
          permission: "",
          role: "veterinary.clinic_owner",
        },
        component: setting,
      },
    ],
  },
];
